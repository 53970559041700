exports.components = {
  "component---src-pages-calendar-survey-tsx": () => import("./../../../src/pages/calendar-survey.tsx" /* webpackChunkName: "component---src-pages-calendar-survey-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-1-tsx": () => import("./../../../src/pages/index-1.tsx" /* webpackChunkName: "component---src-pages-index-1-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-optional-questions-tsx": () => import("./../../../src/pages/optional-questions.tsx" /* webpackChunkName: "component---src-pages-optional-questions-tsx" */),
  "component---src-pages-store-ratings-tsx": () => import("./../../../src/pages/store-ratings.tsx" /* webpackChunkName: "component---src-pages-store-ratings-tsx" */),
  "component---src-pages-voc-cao-tsx": () => import("./../../../src/pages/voc-cao.tsx" /* webpackChunkName: "component---src-pages-voc-cao-tsx" */)
}


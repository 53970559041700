import { red } from "@material-ui/core/colors"
import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: `#002D5C`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
})

export default theme
